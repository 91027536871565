











































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "Contact"
})
export default class Contact extends Vue {
  copiedTel = false;
  copiedMail = false;
  telHover = false;
  telValue = "+48 503 740 261";
  mailHover = false;
  mailValue = "office@twohorizons.pl";

  copyValue(
    value: string,
    callbackSuccess: Function,
    callbackError: Function,
    event: Event
  ) {
    if (this.$store.state.viewPort.width > 1200) {
      event.preventDefault();
      this.$copyText(value).then(
        () => {
          callbackSuccess();
        },
        () => {
          callbackError();
        }
      );
    }
  }

  @Watch("copiedTel")
  disableCopiedTel() {
    setTimeout(() => {
      this.copiedTel = false;
    }, 1000);
  }

  @Watch("copiedMail")
  disableCopiedMail() {
    setTimeout(() => {
      this.copiedMail = false;
    }, 1000);
  }
}
