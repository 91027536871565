var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contactPage"},[_c('div',{staticClass:"wrapper"},[_c('h1',[_vm._v(_vm._s(_vm.$t("contact.contactHeadline")))]),_c('span',[_vm._v(_vm._s(_vm.$t("contact.contactCopy")))]),_c('a',{class:['button', 'primaryButton', _vm.copiedMail && 'copied'],attrs:{"href":("mailto:" + _vm.mailValue)},on:{"mouseenter":function($event){_vm.mailHover = true},"mouseleave":function($event){_vm.mailHover = false},"click":function($event){_vm.copyValue(
          _vm.mailValue,
          function () {
            _vm.copiedMail = true;
          },
          function () {
            _vm.copiedMail = false;
          },
          $event
        )}}},[_vm._v(" "+_vm._s((_vm.copiedMail && _vm.$t("contact.copied")) || (_vm.mailHover && _vm.mailValue) || _vm.$t("contact.emailUs"))+" ")]),_c('a',{class:['button', 'primaryButton', _vm.copiedTel && 'copied'],attrs:{"href":("tel:" + _vm.telValue)},on:{"mouseenter":function($event){_vm.telHover = true},"mouseleave":function($event){_vm.telHover = false},"click":function($event){_vm.copyValue(
          _vm.telValue,
          function () {
            _vm.copiedTel = true;
          },
          function () {
            _vm.copiedTel = false;
          },
          $event
        )}}},[_vm._v(" "+_vm._s((_vm.copiedTel && _vm.$t("contact.copied")) || (_vm.telHover && _vm.telValue) || _vm.$t("contact.callUs"))+" ")]),_c('div',{staticClass:"locations wrapper"},_vm._l((_vm.$store.state.base.contact.addresses),function(address,index){return _c('div',{key:index,staticClass:"location",style:(address.image &&
            '--background-image: url(' +
              require('@/assets/images/contact/' + address.image) +
              ');')},[_c('h2',[_vm._v(_vm._s(address.city))]),_c('span',[_vm._v(_vm._s(address.street))]),_c('span',[_vm._v(_vm._s(address.postalCode))]),_c('span',[_vm._v(_vm._s(address.country))])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }